import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from 'ziggy-js';
import {Ziggy as routes} from './ziggy'
import * as Sentry from "@sentry/vue"
import mitt from 'mitt'

if (routes.defaults) {
    const host = window.location.host
    routes.defaults.domainCompany = host.substring(0, host.indexOf('cupcult.co'))
}

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, typeof Ziggy !== 'undefined' ? Ziggy : routes)

        app.config.globalProperties.emitter = mitt()

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.replayIntegration(),
            ],
            // Session Replay
            replaysSessionSampleRate: 1.0, // TODO: change to 0.1 after testing
            replaysOnErrorSampleRate: 1.0,
        });

        return app.mount(el)
    },
    progress: {
        delay: 300,
        color: '#4B5563',
        showSpinner: true,
    },
});
